import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import defaultImage from '../assets/default-image.jpg'; // Imagem padrão
import '../components/ProductItem.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { FaWhatsapp } from 'react-icons/fa'; // Ícone do WhatsApp

// Botão de WhatsApp reutilizável
const WhatsAppButton = ({ number, name }) => {
  const displayNumber = number.replace(/^55\d{2}/, ''); // Remove o "55" e o DDD
  return (
    <a href={`https://wa.me/${number}`} className="btn-whatsapp">
      <FaWhatsapp className="whatsapp-icon" /> WhatsApp: {displayNumber} - {name}
    </a>
  );
};


WhatsAppButton.propTypes = {
  number: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

// Botão de e-mail reutilizável
const EmailButton = ({ email, subject }) => {
  const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}`;
  return (
    <a href={mailtoLink} className="btn-email">
      <i className="fas fa-envelope"></i> E-mail: pedro@marinaalonsomapas.com.br
    </a>
  );
};

EmailButton.propTypes = {
  email: PropTypes.string.isRequired,
  subject: PropTypes.string,
};

// Componente para exibir os detalhes do produto
const ProductItem = ({ product, onClose }) => {
  const { ref, nome, descricao, imagens, tamanho, observacao } = product;

  const productImage = ref ? require(`../assets/img/${ref}.jpg`) : defaultImage; // Imagem do produto ou a padrão

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  // Lida com erro ao carregar imagem
  const handleImageError = (e) => {
    e.target.src = defaultImage;
  };

  // Abre e fecha o modal de imagem ampliada
  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
  };

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') closeModal();
    };

    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, []);

  return (
    <article className="product-item">
      {/* Imagem Principal */}
      <div>
        <img
          src={productImage}
          alt={`Imagem de ${nome}`}
          onError={handleImageError}
          className="product-image"
        />
      </div>

      {/* Detalhes do Produto */}
      <div className="product-details">
        <section className="product-description">
          <p><strong>Descrição:</strong> {descricao}</p>
          {tamanho && <p><strong>Tamanho:</strong> {tamanho}</p>}
          {observacao && <p className="product-note">{observacao}</p>}
        </section>

        {/* Galeria de Imagens */}
        {imagens && imagens.length > 0 && (
          <section className="product-gallery">
            <h3>Variações:</h3>
            <div className="gallery">
              {imagens.map((img, index) => (
                <div key={index} className="gallery-item">
                  <img
                    src={require(`../assets/img/${img.caminho}.jpg`)}
                    alt={img.nome}
                    onError={handleImageError}
                    className="gallery-image"
                    onClick={() => openModal(img)}
                  />
                  <p><strong>{img.nome}:</strong> {img.descricao}</p>
                </div>
              ))}
            </div>
          </section>
        )}

        {/* Botões de Contato */}
        <h3 className="centralizado">Entre em contato</h3>
        <section className="contact-buttons">
          <WhatsAppButton number="5511973352654" name="Marina" />
          <WhatsAppButton number="5511996867563" name="Pedro" />
          <EmailButton email="pedro@marinaalonsomapas.com.br" subject={`Interesse no produto: ${nome}`} />
        </section>

      </div>

      {/* Modal de Imagem Ampliada */}
      {isModalOpen && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close-modal" onClick={closeModal}>&times;</span>
            <img
              src={require(`../assets/img/${selectedImage.caminho}.jpg`)}
              alt={selectedImage.nome}
              className="modal-image"
            />
            <p>{selectedImage.nome}</p>
          </div>
        </div>
      )}
    </article>
  );
};

ProductItem.propTypes = {
  product: PropTypes.shape({
    ref: PropTypes.string.isRequired,
    nome: PropTypes.string.isRequired,
    descricao: PropTypes.string,
    tamanho: PropTypes.string,
    observacao: PropTypes.string,
    imagens: PropTypes.arrayOf(
      PropTypes.shape({
        caminho: PropTypes.string.isRequired,
        nome: PropTypes.string,
        descricao: PropTypes.string,
      })
    ),
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ProductItem;
