import React, { useState } from 'react';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import ProductItem from './components/ProductItem';
import productsData from './products.json';
import HeroSection from './components/HeroSection';

const App = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [expandedCategory, setExpandedCategory] = useState(null);
  const [expandedSubcategory, setExpandedSubcategory] = useState(null);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setIsOpen(false);  // Fechar o sidebar ao clicar no produto
  };

  const handleCategoryClick = (categoryId) => {
    setExpandedCategory(expandedCategory === categoryId ? null : categoryId);
    setExpandedSubcategory(null); // Resetar subcategoria ao clicar na categoria
  };

  const handleSubcategoryClick = (subcategoryId) => {
    setExpandedSubcategory(expandedSubcategory === subcategoryId ? null : subcategoryId);
  };

  const handleCloseProduct = () => {
    setSelectedProduct(null);
  };

  return (
    <div className="App">
      <Header toggleSidebar={toggleSidebar} />  

      {/* Sidebar */}
      <nav className={`sidebar ${isOpen ? 'open' : ''}`}>
        <ul>
          {Array.isArray(productsData) && productsData.length > 0 ? (
            productsData.map((category) => (
              <li key={category.id}>
                <button
                  className="category-button"
                  onClick={() => handleCategoryClick(category.id)}
                >
                  {category.name || 'Categoria sem nome'}
                </button>

                {expandedCategory === category.id && category.subcategories && category.subcategories.length > 0 && (
                  <ul className="subcategory-list">
                    {category.subcategories.map((subcategory) => (
                      <li key={subcategory.id}>
                        <button
                          className="subcategory-button"
                          onClick={() => handleSubcategoryClick(subcategory.id)}
                        >
                          {subcategory.name || 'Subcategoria sem nome'}
                        </button>

                        {expandedSubcategory === subcategory.id && subcategory.items && subcategory.items.length > 0 && (
                          <ul className="product-list">
                            {subcategory.items.map((item) => (
                              <li key={item.produto?.ref}>
                                <button
                                  className="product-button"
                                  onClick={() => handleProductClick(item.produto)}
                                >
                                  {item.produto?.nome || 'Produto sem nome'}
                                </button>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>
                )}

                {expandedCategory === category.id && category.items && category.items.length > 0 && (
                  <ul className="product-list">
                    {category.items.map((item) => (
                      <li key={item.produto?.ref}>
                        <button
                          className="product-button"
                          onClick={() => handleProductClick(item.produto)}
                        >
                          {item.produto?.nome || 'Produto sem nome'}
                        </button>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))
          ) : (
            <li>Nenhuma categoria disponível</li>
          )}
        </ul>
      </nav>

      <main className="main-content">
        {selectedProduct ? (
          <div className="product-details">
            <ProductItem product={selectedProduct} onClose={handleCloseProduct} />
          </div>
        ) : (
          <HeroSection />
        )}
      </main>

      <Footer />
    </div>
  );
};

export default App;
