import React, { useState } from 'react';
import './Header.css'; // Certifique-se de que este arquivo existe
import { FaBars } from 'react-icons/fa'; // Ícone de hambúrguer

const Header = ({ toggleSidebar }) => {
    return (
        <header className="header">
            <h1>Marina Alonso Mapas</h1>
            {/* Botão de menu hambúrguer para telas pequenas */}
            <button onClick={toggleSidebar} className="burger-menu">
                <FaBars />
            </button>
        </header>
    );
}

export default Header;
