import React from 'react';
import './Footer.css'; // Importa o arquivo de estilo
import LogoBTS from '../assets/img/logoBTS.png'; // Corrigido para usar a imagem corretamente

const Footer = () => {
    return (
        <footer className="footer">
            <p>
                Marina Alonso Mapas - Distribuidora e Comércio de Mapas Comerciais e Personalizados
            </p>
            <div className="footer-developed">
            <span>Desenvolvido por </span>
                <a href="https://bernotechsolution.web.app/" target="_blank" rel="noopener noreferrer" className="footer-link">
                    <img src={LogoBTS} alt="Logo Berno Tech Solutions" className="footer-logo" />
                                    </a>
            </div>
        </footer>
    );
}

export default Footer;
