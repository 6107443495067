import React from 'react';
import './HeroSection.css';
import { FaWhatsapp } from 'react-icons/fa';
import imagemFundo from '../assets/img/imagem_fundo.jpg';

const HeroSection = () => {
    return (
        <section className="hero">
            {/* Imagem de fundo com overlay escurecido */}
            <div className="image-container">
                <img src={imagemFundo} alt="Imagem de produtos" className="scrolling-image" />
            </div>

            {/* Conteúdo centralizado */}
            <div className="hero-content">
                <h2>Quem Somos</h2>
                <p>
                    Na Marina Mapas, nossa paixão é criar mapas que guiam e inspiram. Com um compromisso inabalável com a precisão
                    e a qualidade, oferecemos uma gama exclusiva de soluções personalizadas para empresas, escolas e organizações.
                </p>
                <p>
                    Nosso diferencial está nos detalhes que atendem exatamente às suas necessidades, garantindo confiança em cada um
                    de nossos projetos. Seja para o ambiente acadêmico ou corporativo, cada mapa é projetado para enriquecer seu 
                    conhecimento e facilitar a sua jornada.
                </p>
                <p>
                    Nossa missão é clara: fornecer mapas detalhados e de alta qualidade, que atendam a uma ampla variedade de setores.
                    Se você precisa de soluções de mapeamento precisas e confiáveis, estamos prontos para ajudar.
                </p>
                
                <div className="whatsapp-buttons">
                    <a href="https://wa.me/5511973352654" className="btn-whatsapp">
                        <FaWhatsapp className="whatsapp-icon" /> WhatsApp: 11 97335-2654 - Marina
                    </a>
                    <a href="https://wa.me/5511996867563" className="btn-whatsapp">
                        <FaWhatsapp className="whatsapp-icon" /> WhatsApp: 11 99686-7563 - Pedro
                    </a>
                </div>
            </div>
        </section>
    );
};

export default HeroSection;
